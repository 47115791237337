import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PesquisaService {

  constructor(public http:HttpClient) { }

  getPesquisa(key){
    return new Promise((resolve, reject) => {
      this.http.post("https://srl.pt/wssrl/wssrlv202/filtros.php",JSON.stringify(key))
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

}
