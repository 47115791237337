
import { Device } from '@ionic-native/device/ngx';
import { Market } from '@ionic-native/market/ngx';
import { AppversionService } from './appversion.service';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import {
  LoginService
} from './login.service';
import {
  CarrinhoService
  
} from './carrinho.service';
import {
  ProdutosService
} from './produtos.service';
import {
  OneSignal
} from '@ionic-native/onesignal/ngx';

import {
  Component,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import {
  AppVersion
} from '@ionic-native/app-version/ngx';
import {
  Platform,
  NavController,
  ToastController,
  AlertController,
  PopoverController,
  Events,
  IonMenu,
  MenuController
} from '@ionic/angular';
import {
  SplashScreen
} from '@ionic-native/splash-screen/ngx';
import {
  StatusBar
} from '@ionic-native/status-bar/ngx';
import {
  BehaviorSubject
} from 'rxjs';








@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'

})
export class AppComponent {
  view: any;
  public appPages = [

    {
      title: 'Mercado',
      url: '/menu',
      icon: 'basket',
      component: 'mercadoPage',


    }


  ];

  AppName: string;
  PackageName: string;
  VersionCode: string | number;
  VersionNumber: string;
  public checkNome = false
  public idMercado
  public pageClicada
  public version
  public intervalId
  public nome
  public email
  public telemovel
  public subscribe
  public onesignalID: string = 'f997772b-cdbd-40ab-98ad-2de14a4583de'
  public firebaseID: string = '287848026403'
  public appCodeVersion; // Contém a versão mais recente da aplicação

  constructor(public device: Device, private market: Market, public AppversionService: AppversionService, public appUpdate: AppUpdate,public menu: MenuController, public oneSignal: OneSignal, public events: Events,
    public LoginService: LoginService, private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar, private navCtrl: NavController, private toastCtrl: ToastController, public alertCtrl: AlertController, public ProdutosService: ProdutosService, public CarrinhoService: CarrinhoService, public appVersion: AppVersion, public popoverController: PopoverController, private changeRef: ChangeDetectorRef) {
    this.initializeApp();
    this.platform = platform;



    /* Vai buscar os publish que estão no LoginService.*/
    events.subscribe('nome', nome => {

      this.nome = nome
    });
    events.subscribe('email', email => {

      this.email = email
    });
    events.subscribe('telemovel', telemovel => {

      this.telemovel = telemovel
    });


  }

  ionDidOpen() {
    this.atualizarInf();
  }


  recolherVersaoRecente(){
    this.AppversionService.verificarVersao({key:"verificar_Version"})
    .then(result => {
      this.appCodeVersion = result;
      if(this.appCodeVersion > this.VersionCode){
        alert("Nova versão disponivel na loja!")
        this.market.open("com.ancornet.srl");
        navigator['app'].exitApp();
      }
      
      });
  }

  initializeApp() {
   
    this.platform.ready().then(() => {


      let versaoComparavel; // Irá conter a versão do android sem os numeros após ao primeiro ponto para assim fazer comparacao de versoes

      versaoComparavel = this.device.version
      versaoComparavel= versaoComparavel.split('.')[0];
      parseInt(versaoComparavel) 
      if(this.device.platform == 'Android' && versaoComparavel < 5){
        alert("Devido à versão antiga do seu telemóvel teremos de o reencaminhar para o seu browser");
        window.open("https://loja.srl.pt/", '_system');
        
      }

      this.statusBar.hide();
      this.statusBar.show();
      this.splashScreen.hide();
      
      this.atualizarInf();
      /*** removido temporáriamente para resolver um conflito de versões na appstore */
      /*this.recolherVersaoRecente();*/


      if (this.platform.is('cordova')) {
        this.setupPush();
      }
      if (this.platform.is('ios')) {
        this.setupPush();
      }
      if (this.platform.is('android')) {
        this.setupPush();
      }
    })

  }

  setupPush() {
    this.oneSignal.startInit(this.onesignalID, this.firebaseID);

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe((res) => {

    });

    this.oneSignal.handleNotificationOpened().subscribe((res) => {

    });

    this.oneSignal.endInit();
  }

  atualizarInf() {

    this.appVersion.getAppName().then(value => {
      this.AppName = value;
    }).catch(err => {

    });
    this.appVersion.getPackageName().then(value => {
      this.PackageName = value;
    }).catch(err => {

    });
    this.appVersion.getVersionCode().then(value => {
      this.VersionCode = value;
    }).catch(err => {

    });
    this.appVersion.getVersionNumber().then(value => {
      this.VersionNumber = value;
    }).catch(err => {

    });

      

        this.nome = localStorage.getItem("nomeCliente");
    
   
        this.email = localStorage.getItem("emailCliente")
  

        this.telemovel = localStorage.getItem("telemovelCliente");
      
  }
  async openPage(page) {
    this.pageClicada = page

    if (page.title == 'Mercado' && this.CarrinhoService.carrinho.produtos.length > 0) {
      const alert = await this.alertCtrl.create({
        header: 'Atenção',
        message: 'Tem a certeza que quer mudar de mercado? Se mudar de mercado vai perder os produtos que tem no carrinho',
        buttons: [{
          text: 'Sim',
          handler: async () => {
            sessionStorage.removeItem('MsgCarrinho')
            this.CarrinhoService.carrinho.produtos.length = 0;
            this.navCtrl.navigateRoot('/menu')
          }
        }, {
          text: 'Não',
          handler: () => {

          }
        }]
      });
      await alert.present();
    } else {
      sessionStorage.removeItem('MsgCarrinho')
      this.navCtrl.navigateRoot('/menu')
    }


  }
  login() {
    this.menu.close();
    this.navCtrl.navigateForward('/login')
  }
  async logout() {

    const alert = await this.alertCtrl.create({
      header: 'Atenção',
      message: 'Caso tenha produtos no carrinho, se fizer logout estes serão perdidos!',
      buttons: [{
        text: 'Sim',
        handler: async () => {


          localStorage.clear();
          this.events.unsubscribe('nome', null);
          this.events.unsubscribe('email', null);
          this.events.unsubscribe('telemovel', null);
          this.nome = undefined
          this.telemovel = undefined
          this.email = undefined;
          this.CarrinhoService.carrinho.produtos.length = 0;
          this.navCtrl.navigateRoot('/menu')
          this.LoginService.checkConvidado = true;
          this.menu.close();

        }
      }, {
        text: 'Não',
        handler: () => {

        }
      }]
    });
    await alert.present();
  }

  async appVersionAlert() {
    this.appVersion.getAppName().then(value => {
      this.AppName = value;
    }).catch(err => {

    });
    this.appVersion.getPackageName().then(value => {
      this.PackageName = value;
    }).catch(err => {

    });
    this.appVersion.getVersionCode().then(value => {
      this.VersionCode = value;
    }).catch(err => {

    });
    this.appVersion.getVersionNumber().then(value => {
      this.VersionNumber = value;
    }).catch(err => {

    });


    const alert = await this.alertCtrl.create({
      header: 'App Version',
      message: 'App Name: ' + this.AppName + '<br> ' + '\rVersion Code: ' + this.VersionCode + '<br>' + '\rVersion Number: ' + this.VersionNumber + '<br>' + 'Devoloper: Ancornet.LDA',
      buttons: ['OK']
    });
    await alert.present();
  }


}