import { NavController, AlertController, LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';



 
@Injectable({
  providedIn: 'root'
})
export class ProdutosService {
  public produtoSelecionado: any;
  public mercado: any;
  public unidades: any = [];
  public preco: any = [];
  public produtoCarrinho =[];
  public index;
  public altQuantidade;
  public produto: any[];
  public produtos: any=[];
  isDisabled : boolean = false;
  buttonColor:any;
  public unidadeComentario = ' ';
  public idMercado;
  public mercadoAnterior;
  public mercadoClicado:any;
  public hideme = [];
  public aVer:any;
  public unidadeEscolhida: any;
  public diff
  public mercados
  public checkMercadoAtivo
  public mercadoTempoExtra

  @ViewChild("input") input;
 
 
  
  
  constructor(public http:HttpClient, public navCtrl: NavController, public alertCtrl: AlertController, public loadCtrl: LoadingController) { }
  
  
  getProdutos(key){
    return new Promise((resolve, reject) => {
      this.http.post("https://srl.pt/wssrl/wssrlv202/getProdutos.php",JSON.stringify(key))
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  
    
  getMercados(key){
    return new Promise((resolve, reject) => {
      this.http.post("https://srl.pt/wssrl/wssrlv202/getMercados.php",JSON.stringify(key))
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  async getMercadosInit(){
    
    this.getMercados({key:"lista", idMercado: sessionStorage.getItem('idMercadoSelecionado'), carrinhoCount: sessionStorage.getItem('carrinhoCount')})
    .then(async result => {
      let res:any = result;
      this.mercados = res.mercados;
      let agora = res.agora;
      this.checkMercadoAtivo = res.checkMercadoAtivo
     
      for(let mercado of this.mercados){
        if(mercado.tempoExtra == 1){
          this.mercadoTempoExtra = mercado;

        }
        let hoje = agora;
        let data2 = mercado.data_remocao;
        let data1String: any = new Date(hoje);
        let data2String: any = new Date(data2);
        this.diff = (data2String - data1String);
 
      }

      if(this.checkMercadoAtivo == false){
        this.getMercadosInit();
      }

      if(this.diff < - 850000){
        const alert = await this.alertCtrl.create({
            header: 'Atenção!',
            message: 'O mercado fechou!',
            buttons: [
              {
                text: 'Ok',
                role: '',
                cssClass: 'secondary',
                handler: (blah) => {
                  this.navCtrl.navigateRoot('/menu')
                  this.idMercado = undefined;
                }
              }
              
            ]
          });
      }

      
     
      
    });
  }

  
}