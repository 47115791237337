import {
  PesquisaService
} from './../pesquisa.service';
import {
  LoginService
} from './../login.service';
import {
  CarrinhoService
} from './../carrinho.service';
import {
  AppPage
} from './../../../e2e/src/app.po';
import {
  ProdutosService
} from './../produtos.service';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {
  NavController,
  NavParams,
  LoadingController,
  AlertController,
  ActionSheetController,
  Platform,
  IonList,
  ToastController,
  IonContent,
  IonInfiniteScroll
} from '@ionic/angular';
import {
  Router
} from '@angular/router';
import {
  IonItemSliding
} from '@ionic/angular';
import {
  trigger,
  transition,
  animate,
  style,
} from '@angular/animations'

import {
  StatusBar
} from '@ionic-native/status-bar/ngx';
import {
  AppVersion
} from '@ionic-native/app-version/ngx';




@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.page.html',
  styleUrls: ['./produtos.page.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(-100%)',
          backgroundColor: '#ffffff'
        }),
        animate('500ms ease-in', style({
          transform: 'translateY(0%)',
          backgroundColor: '#ffffff'
        }))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({
          transform: 'translateY(-100%)',
          backgroundColor: '#ffffff'
        }))
      ])
    ])
  ]
})
export class ProdutosPage implements OnInit {
  public input: number = 0;
  public carrinho = {
    produtos: [],
  };
  public produtos: any = [];
  public idMercado: any;
  public produtosSelecionados: any;
  public buttonColor: any;

  public getProdutos: any;
  public preco: any;
  produto: any;
  public textoPesquisa: any
  public countdown
  public mercados: any;
  public unidadeFixa: any;
  public unidadeAl: any;
  public existemUnidadesMercado: any;
  public unidadeEscolhida: any;
  diff
  diffDias
  diffHoras
  diffMins
  clock
  public nomeMercado
  public pagina: any = 0;
  public check: any;
  public unidadeComentario: any;
  public unidades
  public carrinhoCount
  public ctrlCarrinhoCount
  public ctrlIdMercado
  public mercadoDataRemocao
  public dataHoje //A data atual
  public tempoRestante
  public checkMercadoAtivo //checka se oMercado está ativo
  public mercadoTempoExtra //tempo eextra mercado
  public intervalId
  public multiplicacaoPreco
  public totalPrecoProduto //total do preço do produto
  public tipoImportacao // tipo de importação do mercado
  public pesquisa = false //bolean de pesquisa
  public searchinput = '';
  public filtros
  public filtrosMaior
  public siglas = []
  public significado = []
  public objFiltro = [{}]
  public checkSiglas = false //checka se existe siglas ou não
  public nFiltrosAtivos: any = 0;
  public produtoExtra1
  public produtoExtra2
  public produtoExtra3
  public produtoExtra4
  public variedades //usado quando não há siglas e significados nos filtros 
  public checkVariedades = false //checka se há variedades
  public variedadesPesquisar = [] //array com variedades selecionadas pelo cliente
  public ordenacao = 'titulo' // Variavel com o tipo de ordenação dos produtos
  public nome //Nome do Utilizador
  public corButao // muda a cor do butão ordenar 
  public fable = true //faz desaparecer o fab button sempre que abre a pesqusia
  public checkProduto: any = false; //FICA TRUE SE O PRODUTO ESCOLHIDO PELO UTILIZADOR JÁ ESTEJA NO CARRINHO
  public precoCaixa
  public qtdCaixa

  @ViewChild('itemSliding') itemSliding: IonItemSliding;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  constructor(public statusBar: StatusBar, public PesquisaService: PesquisaService, public Platform: Platform, public navCtrl: NavController, public actionCtrl: ActionSheetController, private router: Router, private ProdutosService: ProdutosService, public CarrinhoService: CarrinhoService, public LoginService: LoginService, public loadCtrl: LoadingController, public alertCtrl: AlertController, public toastCtrl: ToastController, public appVersion: AppVersion, ) {
    this.idMercado = this.ProdutosService.mercado.id;
    this.ctrlIdMercado = sessionStorage.setItem('idMercadoSelecionado', this.idMercado);
    this.nomeMercado = this.ProdutosService.mercado.tipo_mercado;
    sessionStorage.setItem('NomeMercadoSelecionado', this.nomeMercado);
    this.mercados = this.ProdutosService.mercado;
    this.unidadeComentario = this.ProdutosService.unidadeComentario;
    this.unidades = this.ProdutosService.mercado.unidades

    this.Platform.ready().then(() => {

      this.Platform.resume.subscribe(() => {
        this.tempoExtra();

        this.updateCheckProduto()

      });
    })
  }


  ionViewWillEnter() {

    if (this.LoginService.checkLogin == true) {
      this.listaProdutos();
      this.LoginService.checkLogin = false;
    }
 
    
  }

  ionViewDidEnter() {
    /* COLOCA O VISTO NOS PRODUTOS QUE SE ENCONTRAM NO CARRINHO */
    this.listaProdutos();
    this.updateCheckProduto()
    this.tempoExtra();
    this.nome = localStorage.getItem("nomeCliente");
  }

  ngOnInit() {

    this.listaProdutos();
    
    this.carrinho = this.CarrinhoService.getCarrinho();
    this.tempoExtra();
    this.tipoImportacao = this.ProdutosService.mercado.tipo_importacao_xls
    this.pesquisaInit();

  }
 
  
  tituloHilverda() {
    if (this.tipoImportacao == 'hilverda') {
      for (let produto of this.produtos) {
        if (produto.altura != null) {
          produto.alturaTitulo = ' | ' + produto.altura
        }
        if (produto.extra1 != null) {
          produto.extra1Titulo = ' | ' + produto.extra1
        }
        if (produto.extra2 != null) {
          produto.extra2Titulo = ' | ' + produto.extra2
        }
        if (produto.extra3 != null) {
          produto.extra3Titulo = ' | ' + produto.extra3
        }
        if (produto.extra4 != null) {
          produto.extra4Titulo = ' | ' + produto.extra4
        }
        if (produto.pais != null) {
          produto.paisTitulo = ' | ' + produto.pais
        }
        if (produto.produtor != null) {
          produto.produtorTitulo = ' | ' + produto.produtor
        }

      }
    } else {
      for (let produto of this.produtos) {
        if (produto.altura != null) {
          produto.alturaTitulo = ' | ' + produto.altura
        }
      }

    }
  }

  listaProdutos() {

    this.pagina = 0
    //this.infiniteScroll.disabled = false;
    let idCliente = localStorage.getItem('idCliente');
    this.ProdutosService.getProdutos({
        key: "lista",
        id_mercado: this.idMercado,
        id_cliente: idCliente,
        filtro: this.searchinput,
        sigla: this.siglas,
        variedade: this.variedadesPesquisar,
        ordenar: this.ordenacao
        //pagina: this.pagina,
        //por_pagina: 25
      })
      .then(async result => {
        let res: any = result;
        
        this.produtos = res.produtos;
        this.updateCheckProduto();
        if (this.produtos !== null) {
          for (let produto of this.produtos) {
            if (idCliente != null) {
              produto.preco = (Math.round(produto.preco * 100) / 100).toFixed(2)
            }
            if (produto.stock_unidades < 0) {
              produto.unidadesEmStock = "disponível";
            } else {
              produto.unidadesEmStock = produto.stock_unidades;
            }
          }
          this.tituloHilverda();
          this.divisaoPreco4();
          this.httpErrorFix()

        } else {
          const alert = await this.alertCtrl.create({
            header: 'Atenção!',
            message: 'Nenhum resultado encontrado',
            buttons: [{
              text: 'Ok',
              handler: () => {
                this.searchinput = ''

                if (this.filtros != undefined) {
                  this.siglas.length = 0
                  for (let filtro of this.filtros) {
                    if (filtro.check == true) {
                      filtro.check = false

                    }
                  }
                }

                if (this.variedades != undefined) {
                  this.variedadesPesquisar.length = 0
                  for (let variedade of this.variedades) {
                    if (variedade.check == true) {
                      variedade.check = false

                    }
                  }
                }
                this.listaProdutos();
                

              }
            }]
          });
          await alert.present();
        }
      })
  }


  ScrollToTop() {
    this.content.scrollToTop(300)
  }

  async ordenarPor() {

    const actionSheet = await this.actionCtrl.create({
      header: 'Ordenar Por',
      buttons: [{
        text: 'Ordenar por Nome',
        icon: this.ordenacao == 'titulo' ? 'checkmark' : 'play',
        handler: () => {

          this.ordenacao = 'titulo'
          this.listaProdutos();
          this.corDoButaoOrdenar()
          this.abrirPesquisa();

        }
      }, {
        text: 'Ordenar por Preço',
        icon: this.ordenacao == 'preço' ? 'checkmark' : 'play',
        handler: () => {

          this.ordenacao = 'preço'
          this.listaProdutos();
          this.corDoButaoOrdenar()
          this.abrirPesquisa();

        }
      }, {
        text: 'Destaques Primeiro',
        icon: this.ordenacao == 'destaque' ? 'checkmark' : 'play',
        handler: async () => {
          var arrayDestaque = []
          for (let produto of this.produtos) {
            if (produto.destaque == '1') {
              arrayDestaque.push(produto.destaque)

            }
          }
          if (arrayDestaque.length >= 1) {
            this.ordenacao = 'destaque'
            this.listaProdutos();
            this.corDoButaoOrdenar()
            this.abrirPesquisa();

          } else {
            this.ordenacao = 'titulo'
            this.listaProdutos();
            this.corDoButaoOrdenar()
            this.abrirPesquisa();

            const toast = await this.toastCtrl.create({
              message: 'Não há produtos com destaque neste mercado!',
              duration: 5000
            });
            await toast.present();
          }

        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {

        }
      }]
    });
    await actionSheet.present();

  }

  corDoButaoOrdenar() {
    if (this.ordenacao != 'titulo') {
      this.corButao = 'danger'
    } else {
      this.corButao = 'primary'
    }

  }


  tempoExtra() {


    if (!this.LoginService.checkCronometro) {
      this.LoginService.cronometro();

      this.intervalId = setInterval(async () => {
        this.clock = this.LoginService.clock;

        this.tempoRestante = this.LoginService.tempoRestante;
        if (this.tempoRestante <= 0) {

          this.tempoRestante = 0;
          this.clock = false
          clearInterval(this.intervalId);
        }
        if (this.CarrinhoService.carrinho.produtos.length == 0) {

          this.clock = false;
          clearInterval(this.intervalId);
        }
      }, 1000)
    }

  }




  /* getProdutosLazyLoading(infiniteScroll: any) {
     
    
     this.pagina++;
     let idCliente = localStorage.getItem('idCliente');
     setTimeout(() => {
       this.getProdutos = this.ProdutosService.getProdutos({
           key: "lista",
           id_mercado: this.idMercado,
           id_cliente: idCliente,
           filtro: this.searchinput,
           sigla: this.siglas,
           preco_cliente: this.preco,
           pagina: this.pagina,
           por_pagina: 25
         })
         .then(result => {
           let res: any = result;
           //this.produtos = res.produtos;
           let produtosNovos = res.produtos;
           
           if (produtosNovos !== null) {
             for (const produto of produtosNovos) {
               this.produtos.push(produto);
             }
             this.updateCheckProduto();
             for (let produto of produtosNovos) {
               produto.preco = Math.round(produto.preco * 100) / 100;
             }

             if (this.tipoImportacao == 'hilverda') {
               for (let produto of produtosNovos) {
                 if (produto.altura != null) {
                   produto.altura = ' | ' + produto.altura
                 } else if (produto.altura == produto.altura) {
                   
                 }
                 if (produto.extra1 != null) {
                   produto.extra1 = ' | ' + produto.extra1
                 }
                 if (produto.extra2 != null) {
                   produto.extra2 = ' | ' + produto.extra2
                 }
                 if (produto.extra3 != null) {
                   produto.extra3 = ' | ' + produto.extra3
                 }
                 if (produto.extra4 != null) {
                   produto.extra4 = ' | ' + produto.extra4
                 }
                 if (produto.pais != null) {
                   produto.extra4 = ' | ' + produto.extra4
                 }
                 if (produto.extra4 != null) {
                   produto.extra4 = ' | ' + produto.extra4
                 }


               }
             }
           
             if (produtosNovos.length < 25) {
               infiniteScroll.target.disabled = true;
             }
            
             
             infiniteScroll.target.complete();



             //this.produtos.push(res.produtos);
             

           } else {
             this.infiniteScroll.disabled = true
             infiniteScroll.target.complete();

           }

         });
     }, 500);
   }*/


  updateCheckProduto() { //Coloca o check nos produtos que estão no carrinho quando atualizamos a lista de produtos
    if (this.produtos != null) {
      for (let produto of this.produtos) {
        if (this.carrinho.produtos.some(({
            id
          }) => id == produto.id)) {
          produto.check = true;
        } else {
          produto.check = false;
        }
      }
    }
  }





  pesquisaInit() {
    this.PesquisaService.getPesquisa({
        key: "pesquisa",
        id_mercado: this.idMercado,

      })
      .then(result => {
        let res: any = result;
        if (this.ProdutosService.mercado.dicionario_familias_sigla == null || this.ProdutosService.mercado.dicionario_familias_sigla == '') {
          if (this.ProdutosService.mercado.dicionario_familias_significado == null || this.ProdutosService.mercado.dicionario_familias_significado == '') {
            this.variedades = res;
            this.checkVariedades = true
          }
        } else {
          this.filtros = res;
          this.checkSiglas = true
        }





      });

  }

  abrirPesquisa() {
    if (this.pesquisa == false) {
      this.pesquisa = true
      this.fable = false
    } else {
      this.pesquisa = false;
      this.fable = true
    }
  }

  pesquisarFiltros(filtro) {


    if (!this.siglas.includes(filtro.sigla)) {
      this.siglas.push(filtro.sigla)
      filtro.check = true
    } else {
      for (var i = 0; this.siglas.length > i; ++i) {
        if (this.siglas[i] == filtro.sigla) {
          this.siglas.splice(i, 1);
        }
      }
      filtro.check = false
    }

    this.nFiltrosAtivos = this.siglas.length;
    let nCaracteresPesquisa = this.searchinput.length;
    if (nCaracteresPesquisa > 0) {
      this.nFiltrosAtivos++;
    }
  }

  pesquisarVariedades(variedade) {

    if (!this.variedadesPesquisar.includes(variedade.variedade)) {
      this.variedadesPesquisar.push(variedade.variedade)
      variedade.check = true
    } else {
      for (var i = 0; this.variedadesPesquisar.length > i; ++i) {
        if (this.variedadesPesquisar[i] == variedade.variedade) {
          this.variedadesPesquisar.splice(i, 1);
        }
      }
      variedade.check = false
    }

    this.nFiltrosAtivos = this.variedades.length;
    let nCaracteresPesquisa = this.searchinput.length;
    if (nCaracteresPesquisa > 0) {
      this.nFiltrosAtivos++;
    }
  }

  aEscreverTextoNaPesquisa() {
    if (this.ProdutosService.mercado.dicionario_familias_sigla == null || this.ProdutosService.mercado.dicionario_familias_sigla == '') {
      if (this.ProdutosService.mercado.dicionario_familias_significado == null || this.ProdutosService.mercado.dicionario_familias_significado == '') {
        this.nFiltrosAtivos = this.variedadesPesquisar.length;
        let nCaracteresPesquisa = this.searchinput.length;
        if (nCaracteresPesquisa > 0) {
          this.nFiltrosAtivos++;
        }
      }
    } else {
      this.nFiltrosAtivos = this.siglas.length;
      let nCaracteresPesquisa = this.searchinput.length;
      if (nCaracteresPesquisa > 0) {
        this.nFiltrosAtivos++;
      }
    }

  }



  async pesquisarProdutos() {

    const loading = await this.loadCtrl.create({
      message: 'Aguarde',
      duration: 2000
    });

    await loading.present().then(() => {
      this.listaProdutos();
      this.content.scrollToTop(300)
      if (this.produtos != null) {
        this.pesquisa = false;
      }
    });



  }


  async limparPesquisa() {

    const loading = await this.loadCtrl.create({
      message: 'Aguarde',
      duration: 500
    });
    await loading.present().then(() => {
      this.searchinput = ''
      this.pagina = 0;
      if (this.checkSiglas) {
        for (let filtro of this.filtros) {
          filtro.check = false;
        }
      }
      if (this.checkVariedades) {
        for (let variedade of this.variedades) {
          variedade.check = false
        }
      }

      this.pesquisa = false;
      this.variedadesPesquisar.length = 0
      this.siglas.length = 0;
      this.content.scrollToTop(300)
      this.ordenacao = 'titulo'
      this.listaProdutos();
      this.corDoButaoOrdenar()
    });




  }

  createButtons(produto) {
    let buttons = [];
    for (let unidade of this.unidades) {
      let button = {
        text: unidade,

        handler: () => {
          this.unidadeEscolhida = unidade
          produto.unidadeEscolhida = this.unidadeEscolhida
          produto.unidadeComentario = this.unidadeComentario;
          produto.check = true;
          produto.n = 1;
          this.CarrinhoService.adicionarProduto(produto);
          this.itemSliding.closeOpened();
          return true;
        }
      }
      buttons.push(button);
    }
    return buttons;
  }

  divisaoPreco4(){

    for(let produto of this.produtos){
      
      if(produto.preco_4 !=null){
        if(produto.preco_4.includes('|') == true){
          console.log(produto.preco_4.includes('|'))
          console.log(produto.titulo)
          var arrPQ;
          var qtdCaixa: number;
          var precoCaixaUn; //float
          arrPQ= produto.preco_4.split("|");
          qtdCaixa = arrPQ[1];
          precoCaixaUn = arrPQ[0];
          if(qtdCaixa > 0){
            produto.qtdCaixa = qtdCaixa;
          }else{
            produto.qtdCaixa = undefined;
          }
          if(precoCaixaUn > 0 ){
            produto.precoCxUn = precoCaixaUn;
            produto.precoCxUn = +produto.precoCxUn;
            produto.precoCxUn= (Math.round(produto.precoCxUn * 100) / 100).toFixed(2);
          }else{
            produto.precoCxUn= undefined;
          }
          
          
          
         
        }else{
          produto.precoCxUn= undefined;
          produto.qtdCaixa= undefined;
        }
      }else{
        produto.precoCxUn= undefined;
        produto.qtdCaixa= undefined;
      }
      
    }
   
  }


  async adicionarCarrinho(produto) {
    

  
    let token = localStorage.getItem('token');
    let idCliente = localStorage.getItem('idCliente');
    this.LoginService.dadosLoginProvider({
      key: 'verificaLogin',
      token: token,
      id_cliente: idCliente
    }).then(async (result) => {
      let res: any = result;
      if (res.login == "true") {

        this.unidadeFixa = produto.unidade_fi;
        this.unidadeAl = produto.unidade_al;
        this.unidadeEscolhida = this.unidadeAl;


        if (this.ProdutosService.mercado.unidades != '') {
          this.existemUnidadesMercado = true;
        }

        for (let produtoCarrinho of this.carrinho.produtos) {
          if (produtoCarrinho.id == produto.id) {
            this.checkProduto = true;
            return
          }
        }
        if (this.checkProduto != true) {
          if ( produto.qtdCaixa == undefined && produto.precoCxUn == undefined ) {
            if (this.existemUnidadesMercado && this.unidadeFixa != 'SIM') {
              if (this.ProdutosService.mercado.unidades.length == 1) {
                for (let unidade of this.ProdutosService.mercado.unidades) {
                  this.unidadeEscolhida = unidade
                }
                produto.unidadeEscolhida = this.unidadeEscolhida;
                produto.check = true;
                produto.n = 1;
                this.multiplicacaoPreco = produto.n * produto.preco
                produto.precoCarrinho = (Math.round(this.multiplicacaoPreco * 100) / 100).toFixed(2)
                this.CarrinhoService.adicionarProduto(produto);
                this.itemSliding.closeOpened();
              } else {
                const actionSheet = await this.actionCtrl.create({

                  header: 'Selecione a unidade',
                  buttons: this.createButtons(produto),
                });
                await actionSheet.present();
              }

            }
            if (produto.unidade_fi == 'SIM' && produto.unidade_al != '') {

              produto.unidadeEscolhida = produto.unidade_al
              produto.check = true;
              produto.n = 1;
              this.multiplicacaoPreco = produto.n * produto.preco
              this.CarrinhoService.adicionarProduto(produto);

              this.itemSliding.closeOpened()
            }

          } else {
            this.ProdutosService.produtoSelecionado = produto
            this.navCtrl.navigateForward('/produto')
          }

          if (this.ProdutosService.mercado.tipo_importacao_xls == 'hilverda') {

            produto.check = true;
            produto.n = 1;

            this.multiplicacaoPreco = produto.n * produto.qtd_minima
            this.totalPrecoProduto = produto.preco * this.multiplicacaoPreco
            produto.precoLinha = (Math.round(this.totalPrecoProduto * 100) / 100).toFixed(2);
            this.CarrinhoService.adicionarProduto(produto);
            this.itemSliding.closeOpened()
          }


        }
        this.checkProduto = false;  
      } else {
        this.itemSliding.closeOpened();
        const alert = await this.alertCtrl.create({
          header: 'Atenção!',
          message: 'Precisa de entrar na sua conta para poder adicionar ao carrinho. Gostaria de entrar?',
          buttons: [{
            text: 'Sim',
            handler: () => {
              this.navCtrl.navigateForward('/login');
            }
          }, {
            text: 'Não'
          }]
        });

        await alert.present();


      }
    }, (err) => {

    });


  }

  /*apagarProdutoCarrinho(produto) {
    if (this.carrinho.produtos.includes(produto)) {
      produto.check = false;
      this.CarrinhoService.removerProduto(produto.id);
      this.itemSliding.closeOpened()
    }
  }*/

  apagarProdutoCarrinho(produto) {
    for (let produtoCarrinho of this.CarrinhoService.carrinho.produtos) {
      if (produto.id == produtoCarrinho.id) {
        produto.check = false;
        this.CarrinhoService.removerProduto(produto.id);
        this.itemSliding.closeOpened()
      }
    }
  }

  async abrirCarrinho() {

    const loading = await this.loadCtrl.create({
      spinner: "bubbles",
      duration: 500,
      message: 'Aguarde...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    return await loading.present().then(async () => {
      let token = localStorage.getItem('token');
      let idCliente = localStorage.getItem('idCliente');
      this.LoginService.dadosLoginProvider({
        key: 'verificaLogin',
        token: token,
        id_cliente: idCliente
      }).then(async (result) => {
        let res: any = result;
        if (res.login == "true") {

          this.navCtrl.navigateForward('/carrinho')
        } else {

          const alert = await this.alertCtrl.create({
            header: 'Atenção!',
            message: 'Precisa de entrar na sua conta para poder ir ao carrinho. Gostaria de entrar?',
            buttons: [{
              text: 'Sim',
              handler: () => {
                this.navCtrl.navigateForward('/login');
              }
            }, {
              text: 'Não'
            }]
          });

          await alert.present();

        }
      }, (err) => {

      });

    });
  }

  async verProduto(produto: any) {
    this.ProdutosService.produtoSelecionado = produto;


    const loading = await this.loadCtrl.create({
      spinner: "bubbles",
      duration: 500,
      message: 'Aguarde...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    loading.present().then(() => {
      this.navCtrl.navigateForward('/produto')
    })
  }

  httpErrorFix() {

    let re = "http://"
    for (let produto of this.produtos) {
      if (this.Platform.is("android")) {
        if (produto.url_imagem_externa.substring(0, 7) == "http://") {
          produto.url_imagem_externa = produto.url_imagem_externa.replace(re, "https://");
        }

      }
    }
  }

}