import { ProdutosService } from './produtos.service';
import { Injectable } from '@angular/core';
import { NavController, AlertController, LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {
  public carrinho = {
    produtos: []
  }
  public unidadeComentario : any;
  public mensagemCarrinho: any;
  public checkSideMenuOpen = false

  constructor(public http:HttpClient, public navCtrl: NavController, public alertCtrl: AlertController, public loadCtrl: LoadingController, public ProdutosService: ProdutosService) { }

  inserirEncomendaCarrinho(key){
    return new Promise((resolve, reject) => {
      this.http.post("https://srl.pt/wssrl/wssrlv202/insertCarrinho.php",JSON.stringify(key))
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  inserirEncomendaCarrinhoTeste(key){
    return new Promise((resolve, reject) => {
      this.http.post("https://joaodevile.com/wssrl/insertCarrinho.php",JSON.stringify(key))
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  

  

  getCarrinho() {
    return this.carrinho;
  }
    
  adicionarProduto(produto) {  
    this.carrinho.produtos.push(produto) 
  }


  removerProduto(id){
    for (var i = this.carrinho.produtos.length - 1; i >= 0; --i) {
      if (this.carrinho.produtos[i].id == id) {
          this.carrinho.produtos.splice(i,1);
          this.ProdutosService.getProdutos('');
      }
    }
  }


  eliminarTodosProdutos(){
    this.carrinho.produtos.splice(0, this.carrinho.produtos.length);
  }
}



