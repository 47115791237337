
import { Device } from '@ionic-native/device/ngx';
import { Market } from '@ionic-native/market/ngx';
import { AppUpdate } from '@ionic-native/app-update/ngx';

import { AppVersion } from '@ionic-native/app-version/ngx';
import { ProdutosPageModule } from './produtos/produtos.module';
import {  HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { NgModule, Directive } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { IonContent, IonGrid, IonicModule, IonicRouteStrategy} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OneSignal } from '@ionic-native/onesignal/ngx';






;

@NgModule({
  declarations: [AppComponent], 
  entryComponents: [],
  imports: [
   
    BrowserAnimationsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ProdutosPageModule,
   
   
  
  ],
  providers: [
   BackgroundMode,
    Device,
    Market,
    AppUpdate,
    StatusBar,
    SplashScreen,
    AppVersion,
    AppComponent,
    OneSignal,
    Directive,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
 
  bootstrap: [AppComponent]
})
export class AppModule {}
