import { HttpClient } from '@angular/common/http';
import xml2js from 'xml2js';  
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppversionService {

  constructor(public http:HttpClient) { }


  verificarVersao(key){
    return new Promise((resolve, reject) => {
      this.http.post("https://srl.pt/wssrl/wssrlv202/versionCheck.php", JSON.stringify(key))
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
}