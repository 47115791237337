import { AppComponent } from './app.component';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ProdutoPageModule } from './produto/produto.module';
import { ProdutosService } from './produtos.service';
import { CarrinhoService } from './carrinho.service';
import { AlertController, NavController, Events } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Key } from 'protractor';
import * as moment from 'moment'

@Injectable({
  providedIn: 'root'
})


export class LoginService {

  public apiUrl='https://srl.pt/wssrl/wssrlv202/login.php';
  public countdown
  public clock
  public messageCheck = false;
  public tempoRestante
  public pararCronometro
  public mercadoDataRemocao
  public diff
  public diffMins
  public intervalId
  public nome
  public email
  public telemovel
  public checkCronometro = false; //verfica se o cronometro foi disparado
  public checkLogin = false; //verfica se está como registado
  public checkConvidado = false //verifica se está como convidado 
  AppName: any;
  VersionCode: any;
  VersionNumber: any;
  constructor(public events: Events, public ProdutoService: ProdutosService, public appVersion: AppVersion, public http: HttpClient, public alertCtrl: AlertController, public navCtrl: NavController, public CarrinhoService: CarrinhoService) { }


  dadosLoginProvider(key){
    key.id_cliente = localStorage.getItem('idCliente'); /****LEVA OS DADOS DO ID DA ASSOCIAÇÃO A SER UTILIZADA*/
    key.token = localStorage.getItem('token'); /****LEVA OS DADOS DO ID DA ASSOCIAÇÃO A SET UTILIZADA*/
    key.nome = sessionStorage.getItem('nome');
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl, JSON.stringify(key))
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

 

async cronometro() {
  this.dadosLoginProvider({
    key: 'tempoExtra',
    idMercado: sessionStorage.getItem('idMercadoSelecionado'),
    carrinhoCount: this.CarrinhoService.carrinho.produtos.length
  }).then(async (result) => {
    let res: any = result;
    this.mercadoDataRemocao = res.data_remocao
    
    if (res.result == 'true') {
      
      this.checkCronometro = true;
      this.clock = true;
      var today: any = moment(new Date());
      var dataRemocao: any = moment(this.mercadoDataRemocao);
      this.diff = moment.duration(dataRemocao.diff(today)) // diferença em milisegundos entre a data de remocao do mercado e a data de hoje
      this.diffMins = this.diff.minutes(); // minutes
      this.countdown = this.diffMins
      this.tempoRestante = 15 + this.countdown
      this.clock = true;
      
      const alert = await this.alertCtrl.create({
        header: 'Atenção!',
        message: 'O mercado fechou com produtos ainda no carrinho! Vamos dar-lhe uns minutos para concluir a sua compra.',
        buttons: [{
            text: 'Ok',
            role: '',
            cssClass: 'secondary',
            handler: (blah) => {

            }
          }

        ]
        
      });
      
      await alert.present();
      this.intervalId = setInterval(async () => {
        
        var today: any = moment(new Date());
        var dataRemocao: any = moment(this.mercadoDataRemocao);
        this.diff = moment.duration(dataRemocao.diff(today)) // diferença em milisegundos entre a data de remocao do mercado e a data de hoje
        this.diffMins = this.diff.minutes(); // minutes
        this.countdown = this.diffMins
        this.tempoRestante = 15 + this.countdown

        if (this.tempoRestante <= 0) {
          this.checkCronometro = false;
          this.tempoRestante = 0;
          clearInterval(this.intervalId);
          const alert = await this.alertCtrl.create({
            header: 'O Tempo Acabou',
            message: 'Os seus 15 minutos acabaram! O Mercado fechou!',
            buttons: [{
              text: 'Ok',
              handler: () => {


              }
            }, ]
          });

          this.CarrinhoService.carrinho.produtos.length = 0;
          this.clock = false;
          sessionStorage.removeItem("idMercadoSelecionado");
          this.navCtrl.navigateRoot('/menu');

          await alert.present();
        }

        if(this.CarrinhoService.carrinho.produtos.length == 0){
          this.checkCronometro = false
          this.clock = false;
          const alert = await this.alertCtrl.create({
            header: 'Atenção!',
            message: 'O mercado fechou!',
            buttons: [{
                text: 'Ok',
                role: '',
                cssClass: 'secondary',
                handler: (blah) => {
    
                }
              }
    
            ]
          });
          sessionStorage.removeItem("idMercadoSelecionado");
          this.navCtrl.navigateRoot('/menu')
          this.CarrinhoService.carrinho.produtos.length = 0
          clearInterval(this.intervalId);
        }

        

        }, 1000)

       

        
    }else if(res.result == '1'){
      const alert = await this.alertCtrl.create({
        header: 'Atenção!',
        message: 'O mercado fechou!',
        buttons: [{
            text: 'Ok',
            role: '',
            cssClass: 'secondary',
            handler: (blah) => {

            }
          }

        ]
      });
      sessionStorage.removeItem("idMercadoSelecionado");
      this.navCtrl.navigateRoot('/menu')
      this.CarrinhoService.carrinho.produtos.length = 0
      await alert.present();
    }

  })

}

atualizarInf(){
  this.appVersion.getAppName().then(value => {
    this.AppName = value;
  }).catch(err => {
    
  });
  this.appVersion.getVersionCode().then(value => {
    this.VersionCode = value;
  }).catch(err => {
   
  });
  this.appVersion.getVersionNumber().then(value => {
    this.VersionNumber = value;
  }).catch(err => {
    
  });

  this.nome= this.events.publish('nome', localStorage.getItem("nomeCliente"));
  this.email= this.events.publish('email', localStorage.getItem("emailCliente"));
  this.telemovel= this.events.publish('telemovel', localStorage.getItem("telemovelCliente"));
  
  
}
 
}
